import {AppBar, Grid, Typography, Button} from '@mui/material';
import { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';

export default function Header1(){

    const [hoverStates, setHoverStates] = useState({
        home: false,
        dijen: false,
      });
    
    const handleHover = (link) => {
    setHoverStates((prevHoverStates) => ({
        ...prevHoverStates,
        [link]: true,
    }));
    };

    const handleLeave = (link) => {
    setHoverStates((prevHoverStates) => ({
        ...prevHoverStates,
        [link]: false,
    }));
    };

    return(
        <AppBar position='fixed' sx={{ height: {xs:'50px', sm:'60px'}, backgroundColor: '#123B37', opacity: 0.9 }}>
            <Grid container alignItems="center">
            <Grid item xs={0.3} sm={0.6} xl={2}></Grid>
            <Grid item xs={9.5} sm={9.5} xl={9}>
                <Button href='/' sx={{textTransform:'none'}} color='secondary'><Typography variant='h4' color='#FFE598' sx={{fontWeight: 400, fontSize: {xs:'20px', sm: '25px'}, letterSpacing: '2px', fontFamily:'Times New Roman'}} className={`${hoverStates.dijen ? 'navLinkHover expand' : 'expand'}`} onMouseEnter={() => handleHover('dijen')} onMouseLeave={() => handleLeave('dijen')}>DiJen Foods Pvt Ltd</Typography></Button>
            </Grid>
            <Grid item xs={2} sm={1.5} xl={0.5}>
                <Button href='/' sx={{textTransform:'none', display:{xs:'none', sm:'block'}}} color='secondary'><Typography variant='body1' color='#FFE598' sx={{pt:{xs: 0, sm: 1}}} className={`${hoverStates.home ? 'navLinkHover expand' : 'expand'}`} onMouseEnter={() => handleHover('home')} onMouseLeave={() => handleLeave('home')}><HomeIcon /></Typography></Button>
                <Button href='/' sx={{textTransform:'none', display:{xs:'block', sm:'none'}}} color='secondary'><Typography variant='body1' color='#FFE598' sx={{pt:0.5}} className={`${hoverStates.home ? 'navLinkHover expand' : 'expand'}`} onMouseEnter={() => handleHover('home')} onMouseLeave={() => handleLeave('home')}><HomeIcon fontSize='small' /></Typography></Button>
            </Grid>
            </Grid>
        </AppBar>
    );
};