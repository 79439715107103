import React from 'react';
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Main from "./components/Main";
import { useMediaQuery } from '@mui/material';
import Footer_main from "./components/Footer_main";
import FooterMobile from "./components/Footer_mobile";
//import { useMediaQuery } from 'react-responsive';
import Franchise from "./components/Franchise";
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Investor from './components/Investor';


export const appTheme = createTheme({
  palette: {
    secondary: {
      main: '#123B37', // we are using secondary in about, otherwise the tabs are not taking custom color.
    },
  },
});
function App() {
  //let theme = responsiveFontSizes(appTheme);
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <ThemeProvider theme={appTheme}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Header />
                <Main />
                {isMobile ? <FooterMobile /> : <Footer_main />}
              </>
            }
          />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/investor" element={<Investor />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
