import React from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import Footer_sub from './Footer_sub';
import Header1 from './Header_sub';

const investors = [
  { id: 1, name: 'John Doe', investmentAmount: 100000 },
  { id: 2, name: 'Jane Smith', investmentAmount: 75000 },
  { id: 3, name: 'Bob Johnson', investmentAmount: 50000 },
  { id: 4, name: 'Alice Brown', investmentAmount: 120000 },
  { id: 5, name: 'Charlie Williams', investmentAmount: 90000 },
  { id: 6, name: 'Eva Davis', investmentAmount: 80000 },
  { id: 7, name: 'Frank Miller', investmentAmount: 60000 },
  { id: 8, name: 'Grace Taylor', investmentAmount: 110000 },
  { id: 9, name: 'Henry Turner', investmentAmount: 95000 },
  { id: 10, name: 'Isabel White', investmentAmount: 70000 },
  { id: 11, name: 'Jack Johnson', investmentAmount: 85000 },
  { id: 12, name: 'Karen Lee', investmentAmount: 105000 },
];

const Investor = () => {

  return (
    <>
    <div style={{height:'100vh'}}>
    <Container sx={{ paddingY: 4 }}>
      <Header1 />
      <Typography variant="h2" align="center" sx={{ marginBottom: 8, marginTop: {xs:10, sm:18} }}>
        Meet Our Investors
      </Typography>
      <Grid container spacing={3}>
        {investors.map((investor) => (
          <Grid key={investor.id} item xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
                  {investor.name}
                </Typography>
                <Typography color="textSecondary">
                  Investment Amount: ${investor.investmentAmount.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    <br /><br />
    <Footer_sub />
    </div>
    </>
  );
};

export default Investor;
