import { Link } from "react-scroll";
import { Link as RouterLink } from 'react-router-dom';
import { useState } from "react";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 800 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 800 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");

    bar.classList.toggle("opened");
  }
  const [hoverStates, setHoverStates] = useState({
    dijen: false,
    about: false,
    brands: false,
    investors: false,
    teams: false,
    contact: false,
    franchise: false,
  });

  const handleHover = (link) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [link]: true,
    }));
  };

  const handleLeave = (link) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [link]: false,
    }));
  };

  const handleLinkClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Close the navigation bar if needed
    openBar();
  };

  return (
    <nav className="navbar">
      <div className="container1">
        <div className="row">
          <h1 className={`logo ${hoverStates.dijen ? 'navLinkHover expand' : 'expand'}`}>
            <Link
              spy={true}
              smooth={true}
              duration={1000}
              to="headerbg"
              style={{ cursor: 'pointer', color: '#FFE598' }}
              onMouseEnter={() => handleHover('dijen')}
              onMouseLeave={() => handleLeave('dijen')}
            >
              DiJen Foods Pvt Ltd
            </Link>
            </h1>
          <ul className="bar">
            <li
              className={`${
                hoverStates.brands ? 'navLinkHover expand' : 'expand'
              }`}
              onMouseEnter={() => handleHover('brands')}
              onMouseLeave={() => handleLeave('brands')}
            >
              <Link
                onClick={openBar}
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                duration={1000}
                style={{ color: '#FFE598' }}
              >
                BRANDS
              </Link>
            </li>
            <li
              className={`${
                hoverStates.about ? 'navLinkHover expand' : 'expand'
              }`}
              onMouseEnter={() => handleHover('about')}
              onMouseLeave={() => handleLeave('about')}
            >
              <Link
                onClick={openBar}
                activeClass="active"
                spy={true}
                smooth={true}
                duration={1000}
                to="about-scroll"
                style={{ color: '#FFE598' }}
              >
                ABOUT US
              </Link>
            </li>
            <li
              className={`${
                hoverStates.teams ? 'navLinkHover expand' : 'expand'
              }`}
              onMouseEnter={() => handleHover('teams')}
              onMouseLeave={() => handleLeave('teams')}
            >
              <Link
                onClick={openBar}
                to="teams"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
                offset={35}
                style={{ color: '#FFE598' }}
              >
                TEAMS
              </Link>
            </li>
            <li
              className={`${
                hoverStates.contact ? 'navLinkHover expand' : 'expand'
              }`}
              onMouseEnter={() => handleHover('contact')}
              onMouseLeave={() => handleLeave('contact')}
            >
              <Link
                onClick={openBar}
                to="contact-top"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
                style={{ color: '#FFE598' }}
                offset={80} // Scroll 50 pixels below the "contact-top" element
              >
                CONTACT US
              </Link>
            </li>
            {/*<li
              className={`${
                hoverStates.investors ? 'navLinkHover expand' : 'expand'
              }`}
              onMouseEnter={() => handleHover('investors')}
              onMouseLeave={() => handleLeave('investors')}
            >
              <RouterLink
                to="/investor"
                onClick={handleLinkClick}
                duration={1000}
                style={{ color: '#FFE598' }}
              >
                Investors
              </RouterLink>
            </li>*/}
            <li
              className={`${
                hoverStates.franchise ? 'navLinkHover expand' : 'expand'
              }`}
              onMouseEnter={() => handleHover('franchise')}
              onMouseLeave={() => handleLeave('franchise')}
            >
              <RouterLink
                to="/franchise"
                onClick={handleLinkClick}
                duration={1000}
                style={{ color: '#FFE598' }}
              >
                FRANCHISE
              </RouterLink>
            </li>
          </ul>

          <div className="button" onClick={openBar}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
