import React from 'react';
import { useState } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';

const cardStyle = {
  width: '100%',
  height: '100%', // Ensure the height matches the width
  background: 'linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))',
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  cursor: 'pointer',
};

const cardStyle1 = {
  width: '90%',
  height: '90%', // Ensure the height matches the width
  background: 'linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))',
  borderRadius: '20px',
  marginLeft: 18,
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  cursor: 'pointer',
};

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
};

const contentStyle1 = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  textAlign: 'center',
  marginTop: '10px'
};

const imageStyle = {
  width: '100%',
  height: '200px',
};

const imageStyle3 = {
  width: '100%',
  height: '100px',
};

const imageStyle1 = {
  width: '100%',
  height: '600px',
  borderRadius: '8px',
  marginBottom: '12px',
};

const imageStyle2 = {
  width: '60%',
  height: '100%',
};

const titleStyle = {
  marginTop: '8px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: '#FFF',
  fontFamily: 'Times New Roman'
};

const titleStyle1 = {
  marginTop: '18px',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  color: '#FFF',
  fontFamily: 'Times New Roman'
};

const descriptionStyle = {
  marginTop: '12px',
  fontSize: '1.3rem',
  color: '#FFF',
  fontFamily: 'Times New Roman'
};

const descriptionStyle1 = {
  marginTop: '5px',
  fontSize: '0.7rem',
  color: '#FFF',
  fontFamily: 'Times New Roman'
};

const AnimatedCard = ({ title, description, imageUrl, info }) => {

  const [isHovered, setIsHovered] = React.useState(false);

  const springProps = useSpring({
    transition: 'transform 0.1s',
    transform: isHovered ? 'scale(1.05)' : 'scale(1)',
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setIsHovered(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsHovered(false);
  };

  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <>
      <animated.div style={isMobile ? { ...cardStyle1 } : { ...cardStyle, ...springProps }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Card elevation={0} style={isMobile ? cardStyle1 : cardStyle} onClick={handleClickOpen}>
          <CardContent style={isMobile ? contentStyle1 : contentStyle}>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ display: { xs: 'block', sm: 'none' } }}>{imageUrl && <img src={imageUrl} alt="Card" style={imageStyle3} />}</Grid>
              <Grid item xs={4} sm={3.5} sx={{ display: { xs: 'none', sm: 'block' } }}>{imageUrl && <img src={imageUrl} alt="Card" style={imageStyle} />}</Grid>
              <Grid item xs={7.5} sm={7.5}>
                <Box sx={{ height: '50px', display: { xs: 'none', lg: 'block' } }} />
                <Typography variant="h5" style={titleStyle} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {title}
                </Typography>
                <Typography variant="body1" style={descriptionStyle} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {description}
                </Typography>
                <Typography variant="h5" style={titleStyle1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  {title}
                </Typography>
                <Typography variant="body1" style={descriptionStyle1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md' disableScrollLock PaperProps={{
          style: {
            borderRadius: 16,
            padding: 5,
            //backgroundColor: '#efebe9'
          },
        }}>
          <CardContent style={contentStyle} onClick={handleClose}>
            <Grid container spacing={2}>
              <Grid item sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>{imageUrl && <img src={imageUrl} alt="Card" style={imageStyle1} />}</Grid>
              <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' }, height: '250px' }}>{imageUrl && <img src={imageUrl} alt="Card" style={imageStyle2} />}</Grid>
              <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <DialogContent>
                <DialogContentText sx={{ fontSize: '1.1rem', fontFamily: 'Times New Roman', fontWeight: 'bold' }}>
                  {title}
                </DialogContentText>
                  <DialogContentText sx={{ fontSize: '0.9rem', fontFamily: 'Times New Roman' }}>{description}</DialogContentText>
                  <br></br>
                  <DialogContentText sx={{ textAlign: 'justify', fontSize: '0.8rem', fontFamily: 'Times New Roman' }}>{info}</DialogContentText>
                </DialogContent>
              </Grid>
              <Grid item sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <DialogTitle sx={{ fontFamily: 'Times New Roman' }}>
                  <Typography variant="h5">{title}</Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText sx={{ fontFamily: 'Times New Roman' }}>
                    <Typography variant="h6">{description}</Typography>
                  </DialogContentText>
                  <br></br>
                  <DialogContentText sx={{ textAlign: 'justify', fontFamily: 'Times New Roman' }}>
                    <Typography variant="body1">{info}</Typography>
                  </DialogContentText>
                </DialogContent>
              </Grid>
            </Grid>
          </CardContent>
        </Dialog>
      </animated.div>
    </>
  );
};

export default AnimatedCard;
