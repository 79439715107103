import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Switch } from '@mui/material';
import videoBackground5 from '../assets/videos/video2.mp4';
import videoBackground6 from '../assets/videos/video1.mp4';
import videoBackground from '../assets/videos/AD.mp4';
import videoBackground1 from '../assets/videos/rest.mp4';
import videoBackground2 from '../assets/videos/AD mobile-optimized.mp4';
import videoBackground3 from '../assets/videos/rest mobile-optimized.mp4';
import videoBackground4 from '../assets/videos/video1 mobile-optimized.mp4';
import videoBackground7 from '../assets/videos/video2 mobile-optimized.mp4';
import dijenFoodsLogo from '../assets/images/D2.png';

function VideoPlayer({ videoSrc }) {
  return <video src={videoSrc} poster={"BG.jpg"}  playsInline loop autoPlay muted></video>;
}

function Header() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
  const [showVideoIndex, setShowVideoIndex] = useState(0);

  const handleToggleVideo = () => {
    setShowVideoIndex((prevIndex) => (prevIndex + 1) % (isDesktop ? 4 : 4));
  };

  useEffect(() => {
    // Set initial video index on component mount
    setShowVideoIndex(Math.floor(Math.random() * (isDesktop ? 3 : 3)));
  }, [isDesktop]);

  const getVideoSource = () => {
    const videos = isDesktop
      //? [videoBackground5, videoBackground6, videoBackground1, videoBackground] this is for desktop
      ? [ videoBackground7, videoBackground3, videoBackground2]
      : [ videoBackground7, videoBackground3, videoBackground2];
    return videos[showVideoIndex];
  };

  return (
    <header>
      <Switch
        checked={showVideoIndex % 2 === 0}
        onChange={handleToggleVideo}
        color="primary"
        inputProps={{ 'aria-label': 'toggle video' }}
        sx={{ display: 'none' }}
      />

      <VideoPlayer videoSrc={getVideoSource()} />

      <a
        href="https://wa.me/7200800886"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <img
        src={dijenFoodsLogo}
        alt="DiJen Foods Logo"
        className="footer-logo"
      />
      <h4 style={{ color: '#FFE598', opacity: 1, marginBottom: '50px', marginTop: '-40px' }}>
        YOUR CULINARY VOYAGE BEGINS HERE
      </h4>
      <div className="headerbg"></div>
    </header>
  );
}

export default Header;
