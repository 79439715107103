import React, { useState, useEffect, useRef } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { Chrono } from "react-chrono";

const useIntersection = (onInView, onOutOfView, options) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onInView();
        } else {
          onOutOfView();
        }
      },
      options
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [onInView, onOutOfView, options]);

  return targetRef;
};

const Home = () => {
  const items = [
    {
      title: "2018",
      cardDetailedText:
      "After numerous sessions and extensive field studies, we entered the Food & Beverage industry by exploring various franchise models. Informed by the gathered information, we stride forward armed with strategic insights and a commitment to innovation.",
      media: {
        type: "IMAGE",
        source: {
          url: "missing_puzzle.jpg",
        },
      },
    },
    {
      title: "2019",
      cardDetailedText: "DiJe Foods was established as a sole proprietorship and assumed control of a franchise, Chop 'N' Stix - an Indo Chinese restaurant at Mugalivakkam, Chennai.",
      media: {
        type: "IMAGE",
        source: {
          url: "hands-of-senior-man-holding-green-seedling.jpg",
        },
      },
    },
    {
      title: "2020-2021",
      cardDetailedText: "Against the backdrop of the challenging pandemic, our resilience and adaptability became our guiding forces, enabling us not just to weather the storm but to emerge stronger than ever.",
      media: {
        type: "IMAGE",
        source: {
          url: "light_ray_of_Hope.jpg",
        },
      },
    },
    {
      title: "2022",
      cardDetailedText: "we explored the intricacies of the F&B industry, adapting to evolving trends and embracing new technologies. Despite challenges, we strategically navigated, laying the groundwork for a robust establishment.",
      media: {
        type: "IMAGE",
        source: {
          url: "rooting.jpg",
        },
      },
    },
    {
      title: "2023",
      cardDetailedText: "DiJen Foods Pvt Ltd has been established to exemplify our unwavering dedication to culinary excellence and customer satisfaction",
      media: {
        type: "IMAGE",
        source: {
          url: "butterfly.jpg",
        },
      },
    },
    {
      title: "2024",
      cardDetailedText: "Embarking on the journey of AsianKatha, we unfold the charm of Pan Asian Cuisine with the inauguration of our inaugural branch in Nungambakkam, Chennai.",
      media: {
        type: "IMAGE",
        source: {
          url: "AKNew.jpg",
        },
      },
    },
  ];

  const greenTheme = {
    primary: "#123B37",
    secondary: "#FFE598",
    cardBgColor: "#FFFFFF",
    cardForeColor: "#000000",
    titleColorActive: "#123B37",
    titleColor: "#123B37",
    fontFamily: "Times New Roman",
  };

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [inChronoViewArea, setinChronoViewArea ] = useState(false);

  useEffect(() => {
    let intervalId;

    if (!isPaused) {
      intervalId = setInterval(() => {
        //setActiveItemIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, 5500); // Change the interval duration as needed (3000 milliseconds = 3 seconds)
    }

    return () => clearInterval(intervalId);
  }, [isPaused, items.length]);

  const handleCardClick = () => {
    setIsPaused(true);

    // Set a timeout to resume the slideshow after a specific duration (e.g., 5 seconds)
    setTimeout(() => {
      setIsPaused(false);
    }, 30000); // Adjust the duration as needed (5000 milliseconds = 5 seconds)
  };

  const handleInViewport = () => {
    // Code to execute when the element is in view
    console.log('The element is in view!');
    if(!inChronoViewArea)
    {
      setinChronoViewArea(true);
      setIsPaused(false);
    }
  };

  const handleOutOfView = () => {
    // Code to execute when the element is out of view
  //  console.log('The element is out of view!');
    // Add more code here as needed
  };

  const targetRef = useIntersection(handleInViewport, handleOutOfView, { threshold: 0.4 });
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <div ref={isMobile ? null : targetRef} onClick={handleCardClick} style={{ width: (isMobile ? '110%' : '100%'), height:(isMobile ? '700px' : '800px'), position: "relative", fontFamily: "Times New Roman", overflow: "hidden"}}>
    {isMobile && (
      <div 
        ref={targetRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "98%",
          height: "96%",
          backgroundImage: "url('path_to_your_image')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: 0.5,
          zIndex: 1,
        }}
      ></div>
    )}
      <Chrono
        items={items}
        mode="VERTICAL_ALTERNATING"
        slideItemDuration={4500}
        theme={greenTheme}
        slideShow
        activeItemIndex={activeItemIndex}
        focusActiveItemOnLoad
        cardHeight={150}
        onClick={handleCardClick}
        style={{ zIndex: 1}}
        //mediaHeight={isMobile ? 75 : 200}
        mediaSettings={{ fit: 'contain' }}
        useReadMore
      >
        {items.map((item, index) => (
          <div key={index}>
            <div className="custom-chrono-item">
              <Typography sx={{fontFamily:'Times New Roman', display:{xs:'none', sm:'block'}}} variant="body2">{item.cardDetailedText}</Typography>
              <Typography sx={{fontFamily:'Times New Roman', display:{xs:'block', sm:'none'}}} variant="caption">{item.cardDetailedText}</Typography>
            </div>
          </div>
        ))}
      </Chrono>
    </div>  
      );
    };

export default Home;
