import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Zoom from '@mui/material/Zoom';

function FooterMobile() {
    const linkStyle = {
        color: '#FFE598',
        fontSize: '15px',
    };

    const handleLinkClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const isMobile = useMediaQuery('(max-width:900px)');

    return (
        <footer>
            <Grid container className="container2">
                <Grid item xs={6} className="side1">
                    <p className="footer-title" style={{ color: '#FFE598' }}>Navigation</p>
                    <ul>
                        <li>
                            <Link spy={true} smooth={true} duration={1000} to="headerbg" style={linkStyle}> Home </Link>
                        </li>
                        <li>
                            <Link to="services" spy={true} smooth={true} duration={1000} style={linkStyle}> Brands </Link>
                        </li>
                        <li>
                            <Link to="about-scroll" spy={true} smooth={true} duration={1000} style={linkStyle}> About Us </Link>
                        </li>
                        {/* Rendering additional links for mobile view */}
                        {isMobile && (
                            <>
                                <li>
                                    <Link to="teams" spy={true} smooth={true} duration={1000} activeClass="active" offset={-50} style={linkStyle}>Teams</Link>
                                </li>
                                <li>
                                    <RouterLink to="/franchise" duration={1000} style={linkStyle} onClick={handleLinkClick}>Franchise</RouterLink>
                                </li>
                            </>
                        )}
                    </ul>
                </Grid>
                <Grid item xs={6} className="side2">
                    <p className="footer-title" style={{ color: '#FFE598' }}>Contact</p>
                    <ul style={{ color: '#FFE598', fontSize: '15px' }}>
                        <li>support@dijenfoods.com</li>
                        <li>+91 7200800886</li>
                    </ul>
                    <p className="footer-title" style={{ color: '#FFE598' }}>Social Media</p>
                    <ul style={{ marginTop: 3 }}>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/dijenfoodspvtltd/" style={linkStyle}><LinkedInIcon /></a>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <div className="footer-icons">
                <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
                    <i className="fas fa-level-up-alt"></i>
                </button>
            </div>
            <Grid container className='copyright' paddingTop={'20px'}>
                <Grid item xs={6}>
                    <div style={{ color: '#FFE598', textAlign: (isMobile ? 'initial' : 'right'), marginLeft: (isMobile ? 18 : 0), fontSize: '0.7rem', paddingTop: (isMobile ? 0 : 3), marginBottom: 0, paddingBottom: 0 }}>
                        &copy; 2024 All Rights Reserved
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ color: '#FFE598', fontSize: '0.7rem' }}>
                    {"Powered By "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="http://mindybots.com"
                            style={{
                                color: '#FFE598',
                                textDecoration: 'none',
                                display: 'inline-block',
                                transition: 'background-color 0.3s, color 0.3s, transform 0.3s',
                                fontSize: '0.7rem'
                            }}
                            onMouseOver={(e) => {
                                e.target.style.color = '#FFF';
                                e.target.style.transform = 'scale(1.05)';
                            }}
                            onMouseOut={(e) => {
                                e.target.style.color = '#FFE598';
                                e.target.style.transform = 'scale(1)';
                            }}
                        >
                            MINDYBOTS
                        </a>
                    </div>
                </Grid>
            </Grid>
        </footer>
    );
}

export default FooterMobile;
