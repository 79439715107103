import emailjs from 'emailjs-com';

const Email = async (formData, data) => {
  try {
    const serviceId = 'service_dldjkfc';
    const templateId = 'template_ys2qjj8';
    const userId = 'EFWJeyLNEqaq_f1G9';

    const templateParams = {
      to_name: 'DiJen Foods',
      from_name: formData.firstName + ' ' + formData.lastName, 
      phone: formData.phoneNumber,
      email: formData.email,
      message: formData.selectBrand,
      brand: data.brand,
      feedback: formData.feedback,
    };

    const result = await emailjs.send(serviceId, templateId, templateParams, userId);

    return result;
  } catch (error) {
    throw new Error(`Error sending email: ${error}`);
  }
};

export { Email };
