import { Grid, useMediaQuery } from '@mui/material';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
//import FacebookIcon from '@mui/icons-material/Facebook';
//import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
  const linkStyle = {
    color: '#FFE598',
  };

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <footer style={{paddingBottom:0, marginBottom:0}}>
      <Grid container className="container2">
        <Grid item xs={12} sm={6} className="side1">
          <Grid container>
            <Grid item xs={12} sm={7.5}>
              <h1 className="logo" style={{ color: '#FFE598' }}>DiJen Foods Pvt Ltd</h1>
            </Grid>
            <Grid item xs={12} sm={2}>
              <p className="footer-title" style={{ color: '#FFE598' }}>Navigation</p>
              <ul>
                <li>
                  <Link spy={true} smooth={true} duration={1000} to="headerbg" style={linkStyle}> Home </Link>
                </li>
                <li>
                  <Link to="services" spy={true} smooth={true} duration={1000} style={linkStyle}> Brands </Link>
                </li>
                <li>
                  <Link to="about-scroll" spy={true} smooth={true} duration={1000} style={linkStyle}> About Us </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <p className="footer-title" style={{ display: (isMobile ? 'none' : 'block'), opacity: 0 }}>Navigation</p>
              <ul>
                <li>
                  <Link to="teams" spy={true} smooth={true} duration={1000} activeClass="active" offset={35} style={{ color: '#FFE598' }}>Teams</Link>
                </li>
                {/*<li>
                  <RouterLink to="/investor" duration={1000} style={{ color: '#FFE598' }} onClick={handleLinkClick}>Investors</RouterLink>
                </li>*/}
                <li>
                  <RouterLink to="/franchise" duration={1000} style={{ color: '#FFE598' }} onClick={handleLinkClick}>Franchise</RouterLink>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className="side2">
          <Grid container>
            <Grid item xs={12} sm={5.5} sx={{ml:{sm:5}}}>
              <p className="footer-title" style={{ color: '#FFE598' }}>Contact</p>
              <ul style={{ color: '#FFE598', fontSize:'15px' }}>
                <li>support@dijenfoods.com</li>
                <li>+91 7200800886</li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={4}>
              <p className="footer-title" style={{color: '#FFE598'}}>Social Media</p>
              <ul style={{ marginTop: 3 }}>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/dijenfoodspvtltd/" style={{ color: '#FFE598'}}><LinkedInIcon /></a>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="footer-icons">
        <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
          <i className="fas fa-level-up-alt"></i>
        </button>
      </div>
      <Grid container spacing={2} className='copyright'>
        <Grid item xs={12} sm={5.9}>
          <div style={{ color: '#FFE598', textAlign: (isMobile ? 'initial' : 'right'), marginLeft: (isMobile ? 18 : 0), fontSize: '0.8rem', paddingTop: (isMobile ? 0 : 3), marginBottom: 0, paddingBottom:0 }}>
            &copy; 2024 DiJen Foods Pvt Ltd. All Rights Reserved
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ul>
            <li style={{ color: '#FFE598', fontSize: '0.8rem', marginLeft: (isMobile ? 18 : 30), textAlign: (isMobile ? 'initial' : 'left'), marginBottom: 0, paddingBottom:0 }}>
              <span> Website Developed by </span>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://mindybots.com"
                style={{
                  color: '#FFE598',
                  textDecoration: 'none',
                  display: 'inline-block',
                  transition: 'background-color 0.3s, color 0.3s, transform 0.3s',
                  fontSize: '0.8rem'
                }}
                onMouseOver={(e) => {
                  e.target.style.color = '#FFF';
                  e.target.style.transform = 'scale(1.05)';
                }}
                onMouseOut={(e) => {
                  e.target.style.color = '#FFE598';
                  e.target.style.transform = 'scale(1)';
                }}
              >
                MINDYBOTS
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;