import About from '../components/About';
import Teams from './Teams';
import Contact from './Contact';
import Brands from './Brand';

function Main() {
  return (
    <>
      <main className='services'>
        <Brands />
        <About />
        <br className='teams'/>
        <Teams />
        <br className="contact"></br>
        <Contact />
        <br></br>
        <br />
      </main>
    </>
  );
}
export default Main;
