import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import Slider from 'react-slick';
import { Container, Grid, Typography, Button, createTheme, ThemeProvider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import debounce from 'lodash/debounce'; // Import debounce

const cardsData = [
  { id: 4, image: 'Chop.png', info: "Chop 'N' Stix has emerged as an iconic destination for Indo-Chinese cuisine at Mugalivakkam, Chennai. Renowned for culinary excellence, we offer an extraordinary dining experience that exceeds expectations. Our unique approach has carved out a niche, making us a beloved local brand. Visit us and become part of shaping the future of flavorful dining." },
  { id: 1, image: 'AsianKatha.jpeg', info: 'Join the AsianKatha family, where Pan Asian excellence meets universal charm. Our restaurant chain blends authentic Asian, Indian, and Continental flavors. Automated kitchens craft healthy meals, ensuring a taste adventure. Invest in a culinary legacy that transcends borders, delivering global appeal with local heart.' },
  //{ id: 2, color: '#123B37', image: 'AsianKatha.jpeg', brand: 'Little AsianKatha', info: 'Join the AsianKatha family, where Pan Asian excellence meets universal charm. Our restaurant chain blends authentic Asian, Indian, and Continental flavors. Automated kitchens craft healthy meals, ensuring a taste adventure. Invest in a culinary legacy that transcends borders, delivering global appeal with local heart.' },
  //{ id: 3, color: '#123B37', image: 'AsianKatha.jpeg', brand: 'Streets of AsianKatha', info: 'Join the AsianKatha family, where Pan Asian excellence meets universal charm. Our restaurant chain blends authentic Asian, Indian, and Continental flavors. Automated kitchens craft healthy meals, ensuring a taste adventure. Invest in a culinary legacy that transcends borders, delivering global appeal with local heart.' },
  
  { id: 5, color: '#123B37', brand: 'Coming Soon', info: 'Exciting news bubbling up! \nWe\'re stirring up something special that will elevate your dining experience. \nStay tuned for a flavorful surprise that\'s set to redefine how you enjoy your favorite meals. \nDelicious moments await!' }
];

const theme = createTheme({
  palette: {
    warning: {
      main: "#F50057",
    },
  },
});

const FlippingCard = ({ card }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const cardProps = useSpring({
    opacity: isFlipped ? 0 : 1,
    transform: `perspective(600px) rotateY(${isFlipped ? 180 : 0}deg) scale(${isFlipped ? 0.95 : 1})`,
    display: isFlipped ? 'none' : 'flex',
  });

  const backCardProps = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped ? 0 : -180}deg) scale(${isFlipped ? 1 : 0.95})`,
    display: isFlipped ? 'flex' : 'none',
  });

  //const handleMouseEnter = () => {
    //if (card.id !== 5) {
      //setIsFlipped(true);
    //}
  //};

    // Debounce the handleMouseLeave function
    const debouncedHandleMouseEnter = debounce(() => {
    
        setIsFlipped(true);
      
    }, 0);

  // Debounce the handleMouseLeave function
  const debouncedHandleMouseLeave = debounce(() => {
      setIsFlipped(false);
  }, 0);

  const isMobile = useMediaQuery('(max-width:900px)');

  const handleClick = () => {
      setIsFlipped(!isFlipped);
  };

  const handleCardClick = () => {
    if (isMobile) {
      handleClick(); // Trigger the flipping animation on mobile
    }
  };

  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsFlipped(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [cardRef]);

  const imageContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  /*const overlayTextStyle = {
    position: 'absolute',
    bottom: isMobile ? 40 : 45,
    left: isMobile ? '30%' : 225,
    color: 'white',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
  };

  const overlayTextStyle1 = {
    position: 'absolute',
    bottom: isMobile ? 40 : 45,
    left: isMobile ? '25%': 200,
    color: 'white',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
  };*/

  const overlayTextStyle2 = {
    position: 'absolute',
    bottom: isMobile ? 45 : 55,
    left: isMobile ? '34%' : 246,
    fontWeight: 'bold',
    // /textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
  };

  return (
    <div>
    <Grid
      style={{
        display: 'flex',
        overflowX: 'hidden',
        height: (isMobile ? '300px': '400px'),
        width: '97%',
        justifyContent: 'center',
      }}
      onMouseEnter={isMobile ? '' : debouncedHandleMouseEnter}
      onMouseLeave={isMobile ? '' : debouncedHandleMouseLeave}
      onClick={handleCardClick}
      className='brand'
      ref={cardRef}
    >
      <animated.div
        className="card"
        style={{
          ...cardProps,
          backgroundColor: card.color,
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          boxSizing: 'border-box',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {card.id === 1 && <img src={card.image} style={{ height: '100%', width: '100%' }} alt='card' />}
        {/*card.id === 2 && 
          <div style={imageContainerStyle}>
            <img src={card.image} style={{ height: '100%', width: '100%' }} alt='card'></img>
            <Typography variant='h4' sx={{ color: '#FFE598', fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }} style={overlayTextStyle}>{card.brand}</Typography>
            <Typography variant='body1' sx={{ color: '#FFE598', fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }} style={overlayTextStyle}>{card.brand}</Typography>
          </div>
        }
        {card.id === 3 && 
          <div style={imageContainerStyle}>
            <img src={card.image} style={{ height: '100%', width: '100%' }} alt='card'></img>
            <Typography variant='h4' sx={{ color: '#FFE598', fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }} style={overlayTextStyle1}>{card.brand}</Typography>
            <Typography variant='body1' sx={{ color: '#FFE598', fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }} style={overlayTextStyle1}>{card.brand}</Typography>
          </div>
        */}
        {card.id === 4 && 
         <div style={imageContainerStyle}>
           <img src={card.image} style={{ height: '100%', width: '100%' }} alt='card'></img>
           <Typography variant='h5' sx={{ color: '#FFE200', fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }} style={overlayTextStyle2}>{card.brand}</Typography>
           <Typography variant='body1' sx={{ color: '#FFE200', fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }} style={overlayTextStyle2}>{card.brand}</Typography>
         </div>
        }
        {card.id === 5 && <Typography variant='h2' sx={{ color: '#FFE598', mt: 4, fontFamily:'Times New Roman' }}>{card.brand}</Typography>}
      </animated.div>

      <animated.div
        className="card"
        style={{
          ...backCardProps,
          background: '#123B37',
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          boxSizing: 'border-box',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          opacity: 1
        }}
      >
        {card.id !== 7 &&
          <>
            <Typography variant="h6" style={{ padding: 6, color: '#FFE598', textAlign:'justify', display: (isMobile ? 'none' : 'block'), fontFamily:'Times New Roman' }}>{card.info}</Typography>
            <Typography variant="body2" style={{ padding: 6, color: '#FFE598', textAlign:'justify', display: (isMobile ? 'block' : 'none'), fontFamily:'Times New Roman' }}>{card.info}</Typography>
          </>
        }
        {card.id === 1 && 
          <>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='http://asiankatha.com/' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'none' : 'block')}}>Know more</Button></ThemeProvider>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='http://asiankatha.com/' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'block' : 'none'), fontSize:'0.6rem'}} size='small'>Know more</Button></ThemeProvider>
          </>
        }
        {/*card.id === 2 && 
          <>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='http://asiankatha.com/' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'none' : 'block')}}>Know more</Button></ThemeProvider>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='http://asiankatha.com/' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'block' : 'none'), fontSize:'0.6rem'}} size='small'>Know more</Button></ThemeProvider>
          </>
        }
        {card.id === 3 && 
          <>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='http://asiankatha.com/' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'none' : 'block')}}>Know more</Button></ThemeProvider>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='http://asiankatha.com/' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'block' : 'none'), fontSize:'0.6rem'}} size='small'>Know more</Button></ThemeProvider>
          </>
        */}
        {card.id === 4 && 
          <>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='https://www.instagram.com/chopnstix_porur?igsh=YzV3ZnRwNXBqdjRj' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'none' : 'block')}}>Know more</Button></ThemeProvider>
            <ThemeProvider theme={theme}><Button color='warning' variant='contained' target="_blank" rel="noreferrer" href='https://www.instagram.com/chopnstix_porur?igsh=YzV3ZnRwNXBqdjRj' sx={{fontFamily:'Times New Roman', display: (isMobile ? 'block' : 'none'), fontSize:'0.6rem'}} size='small'>Know more</Button></ThemeProvider>
          </>
        }
        
      </animated.div>
    </Grid>
    </div>
  );
};

const Brands = () => {
  const isMobile = useMediaQuery('(max-width:900px)');

  const sliderRef = useRef(null);

  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: isMobile ? 2000 : 3000,
    pauseOnHover: true,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    speed: isMobile ? 1300 : 2000,
    cssEase: "linear",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting) {
          setSettings({
            ...settings,
            autoplay: true, // Update autoplay based on whether the slider is in view
          });
        }

      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
    };
  }, []);


  const headingStyle = {
    // Default styles (for desktop)
    color: '#123B37',
    fontSize: isMobile ? '30px' : '48px',
    textAlign: 'center',
    marginBottom: '50px',
  
    // Styles for mobile
    '@media (max-width: 600px)': {
      fontSize: '30px',
    },
  };

  return (
    <Container maxWidth='xl'>
      <h2 style={headingStyle}>KNOW OUR BRANDS</h2>
      <Grid container spacing={2} maxWidth={{xs:'95%', sm:'100%'}} sx={{ ml: 1 }}>
        <Grid ref={sliderRef} item xs={12}>
          <Slider {...settings} key={settings.autoplay ? 'autoplay' : 'no-autoplay'}>
            {cardsData.map((card) => (
              <FlippingCard key={card.id} card={card} />
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Brands;
