import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { sendEmail } from './email_contactus';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid, FormControl } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const theme = createTheme({
  palette: {
    primary: {
      main: '#123B37',
    },
  },
});


const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    feedback: '',
    errorMessages: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      feedback: '',
    },
  });

  const [formModified, setFormModified] = useState(false);

  const handlePhoneChange = (value) => {
    setFormModified(true);
    let errorMessage = '';

  // Check if the phone number has at least 9 digits and consists only of numeric characters
    errorMessage = value.length >= 15 ? '' : 'Invalid phone number';

    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: value,
      errorMessages: {
        ...prevData.errorMessages,
        phoneNumber: errorMessage,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormModified(true);

    if (name === 'lastName' && value.toLowerCase() === formData.firstName.toLowerCase()) {
      return;
    }

    // Regular expressions for validation
    const nameRegex = /^[A-Za-z\s]*$/;

    // Validation logic
    let isValid = true;
    let errorMessage = '';

    switch (name) {
      case 'firstName':
        isValid = nameRegex.test(value);
        errorMessage = value.length < 3 ? 'First name must be at least 3 characters' : '';
        break;
      case 'lastName':
        isValid = nameRegex.test(value);
        errorMessage = value.length < 3 ? 'Last name must be at least 3 characters' : '';
        break;
      case 'email':
        const atIndex = value.indexOf('@');
        const dotComIndex = value.indexOf('.com');
        errorMessage =
          atIndex !== -1 && dotComIndex !== -1 && atIndex < dotComIndex && dotComIndex === value.length - 4
            ? ''
            : 'Incorrect Email';
          if (dotComIndex !== -1 && dotComIndex !== value.length - 4) {
            errorMessage = 'Incorrect Email';
          }
        break;
      default:
        break;
    }

    if (isValid) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        errorMessages: {
          ...prevData.errorMessages,
          [name]: errorMessage,
        },
      }));
    }
  };

  const isFormValid = () => {
    // Check if any required field is empty
    return (
      Object.values(formData).every((value) => value !== '') &&
      Object.values(formData.errorMessages).every((error) => error === '')
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      alert('Please fill in all fields.'); // Display an error message
      return;
    }

    try {
      // Send feedback email using EmailJS
      handleOpen();
      await sendEmail(formData);
      console.log('Request submitted');
      handleClose();
      handleDialogOpen(); // Open the dialog
      setFormModified(false);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        feedback: '',
        errorMessages: {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          feedback: '',
        },
      });

    } catch (error) {
      console.error('Error submitting request', error);
      alert('Failed to submit request. Please try again later.');
      handleClose();
      setFormModified(false);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        feedback: '',
        errorMessages: {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          feedback: '',
        },
      });
    }
  };

  const [fontSize, setFontSize] = useState('48px');

  useEffect(() => {
    const updateFontSize = () => {
      // Adjust the font size based on the window width
      if (window.innerWidth < 768) {
        setFontSize('30px');
      } else {
        setFontSize('48px');
      }
    };

    // Update font size on mount and whenever the window is resized
    updateFontSize();
    window.addEventListener('resize', updateFontSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateFontSize);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  const headingStyle = {
    color: '#123B37',
    fontSize: fontSize,
    marginLeft: 20,
  };


  return (
    <div style={{ padding: '16px' }}>
      <Container maxWidth="lg">
        <h2 id = "contact-top" style={headingStyle}>
          GET ANSWERS TO YOUR QUESTIONS
        </h2>
        <Box      
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            borderRadius: 8,
          }}
        > 
          <form onSubmit={handleSubmit} style={{ width: '100%' }}> 
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={Boolean(formData.errorMessages.firstName)}
                helperText={formData.errorMessages.firstName}
                color='secondary'
              />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={Boolean(formData.errorMessages.lastName)}
                  helperText={formData.errorMessages.lastName}
                  color='secondary'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(formData.errorMessages.email)}
                helperText={formData.errorMessages.email}
                color='secondary'
              />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin='normal'>
                  <MuiTelInput label="Phone Number" fullWidth value={formData.phoneNumber} onChange={handlePhoneChange} defaultCountry='IN' color='secondary' error={Boolean(formData.errorMessages.phoneNumber)} helperText={formData.errorMessages.phoneNumber} InputProps={{ inputProps: { maxLength: 15, },}} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  multiline
                  rows={4}
                  name="feedback"
                  value={formData.feedback}
                  onChange={handleChange}
                  color='secondary'
                />
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isFormValid() || !formModified}
                  sx={{ mt: 3 }}
                >
                  Submit
                </Button>
                </ThemeProvider>
              </Grid>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{height:{xs:'none', sm:'200px'}}}
                fullWidth
                maxWidth='md' 
                PaperProps={{
                  style: {
                    borderRadius: 16,
                    backgroundColor: '#efebe9',
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title" sx={{ color: '#123B37', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize:'2rem' }}>
                  {"Request submitted successfully."}
                </DialogTitle>
                <DialogContent sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', mt:1 }}>
                  <DialogContentText id="alert-dialog-description" sx={{ color: '#000'}}>
                    Thank you for your submission! We will get back to you soon.
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </Grid>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default Contact;
