import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, useMediaQuery } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Footer_sub from './Footer_sub';
import Header1 from './Header_sub';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Email } from './email_franchise';
import { MuiTelInput } from 'mui-tel-input';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const theme = createTheme({
  palette: {
    primary: {
      main: '#123B37',
    },
  },
});


const Franchise = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    selectBrand: '',
    feedback: '',
    errorMessages: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      feedback: '',
    },
  });

  const [data, setData] = useState({
    brand: '',
    errorMessages: {
      brand: '',
    },
  });

  const [formModified, setFormModified] = useState(false);

  const [alphabetsValue, setAlphabetsValue] = useState('');
  data.brand = alphabetsValue;

  const handlePhoneChange = (value) => {
    setFormModified(true);
    let errorMessage = '';

  // Check if the phone number has at least 9 digits and consists only of numeric characters
    errorMessage = value.length >= 15 ? '' : 'Invalid phone number';

    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: value,
      errorMessages: {
        ...prevData.errorMessages,
        phoneNumber: errorMessage,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormModified(true);
  
    if (name === 'lastName' && value.toLowerCase() === formData.firstName.toLowerCase()) {
      return;
    }
  
    // Regular expressions for validation
    const nameRegex = /^[A-Za-z\s]*$/;
  
    // Validation logic
    let isValid = true;
    let errorMessage = '';
  
    switch (name) {
      case 'firstName':
        isValid = nameRegex.test(value);
        errorMessage = value.length < 3 ? 'First name must be at least 3 characters' : '';
        break;
      case 'lastName':
        isValid = nameRegex.test(value);
        errorMessage = value.length < 3 ? 'Last name must be at least 3 characters' : '';
        break;
      case 'email':
        const atIndex = value.indexOf('@');
        const dotComIndex = value.indexOf('.com');
        errorMessage =
          atIndex !== -1 && dotComIndex !== -1 && atIndex < dotComIndex && dotComIndex === value.length - 4
          ? ''
          : 'Incorrect Email';
          if (dotComIndex !== -1 && dotComIndex !== value.length - 4) {
            errorMessage = 'Incorrect Email';
          }
        break;
      default:
        break;
    }
  
    if (isValid) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        errorMessages: {
          ...prevData.errorMessages,
          [name]: errorMessage,
        },
      }));
    }
  };

  const handleAlphabetsChange = (event) => {
    // Use a regular expression to allow only alphabets
    const newValue = event.target.value.replace(/[^A-Za-z ]/g, '');
    setAlphabetsValue(newValue);
  };

  const handleTextfield = () => {
     setAlphabetsValue('');
  };

  const isFormValid = () => {
    // Check if any required field is empty
    return (
      Object.values(formData).every((value) => value !== '') &&
      Object.values(formData.errorMessages).every((error) => error === '')
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      alert('Please fill in all fields.'); // Display an error message
      return;
    }

    try {
      // Send feedback email using EmailJS
      handleOpen();
      await Email(formData, data);
      console.log('Enquiry submitted');
      handleClose();
      handleDialogOpen();
      setFormModified(false);
      handleTextfield();

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        selectBrand: '',
        feedback: '',
        errorMessages: {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          selectBrand: '',
          feedback: '',
        },
      });

      setData((prevData) => ({
        ...prevData,
        brand: '',
        errorMessages: {
          brand: '',
        },

      }));
    
    } catch (error) {
      console.error('Error submitting enquiry', error);
      alert('Failed to submit enquiry. Please try again later.');
      handleClose();
      setFormModified(false);
      handleTextfield();
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        selectBrand: '',
        feedback: '',
        errorMessages: {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          selectBrand: '',
          feedback: '',
        },
      });

    }
  };

  const selectBrands = ['AsianKatha', 'Little AsianKatha', 'Streets of AsianKatha','Others'];

  const isMobile = useMediaQuery('(max-width:600px)'); 

  return (
    <>
    <div style={{ backgroundColor: (isMobile ? '#ffffff' : '#e8eaf6' ), minHeight: '85vh', padding: '12px' }}>
    <Header1 />
    <Container maxWidth="lg">
      <Box
        component={isMobile ? '' : Paper}
        elevation={3}
        sx={{
          padding: 4,
          display:'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          marginTop: {xs:3, sm: 20},
          borderRadius: 8,
        }}
      >
        <Typography variant="h4" gutterBottom sx={{mb:3, display:{xs:'none', sm:'block'}, color:'#123B37', fontFamily:'Times New Roman'}}>
          Franchise Enquiry
        </Typography>
        <Typography variant="h5" gutterBottom sx={{mb:3, display:{xs:'block', sm:'none'}, color:'#123B37', fontFamily:'Times New Roman'}}>
          Franchise Enquiry
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={Boolean(formData.errorMessages.firstName)}
                helperText={formData.errorMessages.firstName}
                color='secondary'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={Boolean(formData.errorMessages.lastName)}
                helperText={formData.errorMessages.lastName}
                color='secondary'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(formData.errorMessages.email)}
                helperText={formData.errorMessages.email}
                color='secondary'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin='normal'>
                  <MuiTelInput label="Phone Number" fullWidth value={formData.phoneNumber} onChange={handlePhoneChange} defaultCountry='IN' color='secondary' error={Boolean(formData.errorMessages.phoneNumber)} helperText={formData.errorMessages.phoneNumber} InputProps={{inputProps: {maxLength: 15,},}} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="selectBrand-label" color='secondary'>Select Brand</InputLabel>
                  <Select
                    labelId="selectBrand-label"
                    id="selectBrand"
                    value={formData.selectBrand}
                    label="Select Brand"
                    name="selectBrand"
                    onChange={handleChange}
                    color='secondary'
                  >
                    {selectBrands.map((brand, index) => (
                      <MenuItem key={index} value={brand}>
                        {brand}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formData.selectBrand === 'Others' && (
                  <TextField
                    label="Enter your brand"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="brand"
                    value={data.brand}
                    onChange={handleAlphabetsChange}
                    color='secondary'
                    InputProps={{
                      inputProps: {
                        maxLength: 16,
                      },
                    }}
                  />
                )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant='outlined'
                fullWidth
                margin='normal'
                multiline
                rows={4}
                name="feedback"
                value={formData.feedback}
                onChange={handleChange}
                color='secondary'
              />
            </Grid>
            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isFormValid() || !formModified} 
                sx={{mt:3}}
              >
                Submit
              </Button>
              </ThemeProvider>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
              >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{height:{xs:'none', sm:'200px'}}}
              fullWidth
              maxWidth='md' 
              PaperProps={{
                style: {
                  borderRadius: 16,
                  backgroundColor: '#efebe9',
                },
              }}
            >
              <DialogTitle id="alert-dialog-title" sx={{ color: '#123B37', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize:'2rem' }}>
                {"Enquiry submitted successfully."}
              </DialogTitle>
              <DialogContent sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', mt:1 }}>
                <DialogContentText id="alert-dialog-description" sx={{ color: '#000'}}>
                  Thank you for your submission! We will get back to you soon.
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Grid>
        </form>
      </Box>
    </Container>
    </div>
    <Footer_sub />
    </>
  );
};

export default Franchise;
