import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActionArea from '@mui/material/CardActionArea';
//import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';
import Home from './Chrono';
import { useMediaQuery } from '@mui/material';


const cards = [
  {
    title: 'Menu Curation',
    content: 'Collaborate with expert chefs for meticulous menu curation. Represent authentic tastes and culinary traditions from various Asian countries. Ensure precise ingredient proportions and cooking methods using automated kitchen equipment.',
  },
  {
    title: 'AI-Powered Personalization',
    content: 'Utilize AI to learn guest preferences, dietary requirements, and past orders. Recommend personalized menu options tailored to each guest\'s taste. Enable guests to explore new flavors and enjoy customized dining adventures.',
  },
  {
    title: 'Operational Efficiency with AI',
    content: 'Implement intelligent inventory management and automated order processing. Optimize efficiency, minimize wait times, and provide a seamless dining experience.',
  },
  {
    title: 'Balancing Tradition and Innovation',
    content: 'Integrate AI while respecting and honoring the cultural heritage of Asian cuisine. Strive for a harmonious balance between tradition and innovation. Showcase the skilled craftsmanship of our chefs in every dish.',
  },
  {
    title: 'Creating a Dining Destination',
    content: 'Offer an immersive experience in Asian flavors with AI-driven personalization. Guests to leave with memories of outstanding culinary adventures. Redefine the boundaries of Pan Asian cuisine through AI.',
  },
];

const anothercard = [
  {
    title: 'AI-Powered Personalization',
    content: 'Analyze guest preferences using AI. Tailor menu recommendations based on dietary restrictions and preferences. Continuous learning and adaptation for exceptional dining experiences.',
  },
  {
    title: 'Efficiency and Streamlining with AI',
    content: 'Automate order processing. Optimize inventory management. Provide a seamless and efficient dining experience.',
  },
  {
    title: 'Preserving Authenticity',
    content: 'Curate a menu with traditional recipes, local ingredients, and culinary techniques. Blend heritage and innovation in each dish.',
  },
  {
    title: 'Atmosphere & Experience',
    content: 'Blend Asian hospitality and AI innovation. Create a place for culinary adventure. Embrace the convenience and personalization of AI.',
  },
];


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function About() {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <>
      <div style={{ marginTop: '0rem', width: '100%', height: '10px' }} className="about-scroll"></div>

      <div className="container">
        <div className="row">
          <div>
            <p className="main-p">

            </p>
            <br></br><br></br>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="secondary tabs example" variant="fullWidth"
                  textColor="secondary"
                  indicatorColor="secondary"
                  sx={{
                    //"& button:hover": {backgroundColor: '#123B37'}
                    //opacity: 0.8, // Second opacity value
                  }}
                >
                  <Tab label={<h2 >OUR Journey</h2>} sx={{fontFamily:'Times New Roman', fontSize:(isMobile ? '0.7rem' : '1rem')}} />
                  <Tab label={<h2 >MISSION</h2>} sx={{fontFamily:'Times New Roman', fontSize:(isMobile ? '0.7rem' : '1rem')}} />
                  <Tab label={<h2 >VISION</h2>} sx={{fontFamily:'Times New Roman', fontSize:(isMobile ? '0.7rem' : '1rem')}} />

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Typography sx={{fontFamily:'Times New Roman', display:(isMobile ? 'none': 'block'), fontSize:'1.2rem'}} variant='h6'>Established in 2019 as a sole proprietorship, <strong>DiJe Foods</strong> transitioned into DiJen Foods Pvt Ltd by 2023. Our inaugural venture, Chop 'N' Stix (FOFO), an Indo-Chinese restaurant, commenced operations at Mugalivakkam, Chennai in August 2019. Currently, with immediate plans, we are unveiling AsianKatha, a Pan-Asian cuisine restaurant. This marks a significant stride towards realizing our visionary aspirations. Our commitment to consistency in authenticity, quality and quantity, is integral to our mission and growth.</Typography>
                <Typography sx={{fontFamily:'Times New Roman', display:(isMobile ? 'block': 'none'), fontSize:'0.95rem'}} variant='body2'>Established in 2019 as a sole proprietorship, <strong>DiJe Foods</strong> transitioned into DiJen Foods Pvt Ltd by 2023. Our inaugural venture, Chop 'N' Stix (FOFO), an Indo-Chinese restaurant, commenced operations at Mugalivakkam, Chennai in August 2019. Currently, with immediate plans, we are unveiling AsianKatha, a Pan-Asian cuisine restaurant. This marks a significant stride towards realizing our visionary aspirations. Our commitment to consistency in authenticity, quality and quantity, is integral to our mission and growth.</Typography>
                <br /><br /><br />
                <Home />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  {cards.map((card, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                      <Zoom in={true} timeout={500 * index}>
                        <Card className="aboutus-hover"> {/* Add shaking-card class */}
                          <CardActionArea sx={{ background: '#F5F5F5' }}>
                            <CardContent>
                              <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 3, color: '#00796B', fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }}>
                                {card.title}
                              </Typography>
                              <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 3, color: '#00796B', fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }}>
                                {card.title}
                              </Typography>
                              <Typography variant="h6" sx={{ textAlign: 'center', color: '#555', marginBottom: 2, fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }}>
                                {card.content}
                              </Typography>
                              <Typography variant="body1" sx={{ textAlign: 'center', color: '#555', marginBottom: 2, fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }}>
                                {card.content}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        </Zoom>
                    </Grid>
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid container spacing={2}>
                  {anothercard.map((card, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                       <Zoom in={true} timeout={500 * index}>
                        <Card className="aboutus-hover"> {/* Add shaking-card class */}
                          <CardActionArea sx={{ background: '#F5F5F5' }}>
                            <CardContent>
                              <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 3, color: '#00796B', fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }}>
                                {card.title}
                              </Typography>
                              <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 3, color: '#00796B', fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }}>
                                {card.title}
                              </Typography>
                              <Typography variant="h6" sx={{ textAlign: 'center', color: '#555', marginBottom: 2, fontFamily:'Times New Roman', display:{xs:'none', sm:'block'} }}>
                                {card.content}
                              </Typography>
                              <Typography variant="body1" sx={{ textAlign: 'center', color: '#555', marginBottom: 2, fontFamily:'Times New Roman', display:{xs:'block', sm:'none'} }}>
                                {card.content}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        </Zoom>
                    </Grid>
                  ))}
                </Grid>
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
